<template>
    <el-dialog title="发布固件" :visible.sync="dialogVisible" top="0vh" center width="450px"
               @close='closeFirmwareReleasePage' :close-on-click-modal="false" :destroy-on-close=true>
        <el-form ref="releaseFirmwareInfoForm" :model="releaseInfoDto" label-width="100px" :rules="rules" size="mini">
            <el-form-item label="产品型号">
                <span>{{ productModelName }}</span>
            </el-form-item>
            <el-form-item label="固件类型">
                <span>{{ releaseInfoDto.type }}</span>
            </el-form-item>
            <el-form-item label="固件版本">
                <span>{{ version }}</span>
            </el-form-item>
            <el-form-item label="发布原因" prop="remark">
                <el-input type="textarea" :rows="5" placeholder="请填写发布原因" v-model="releaseInfoDto.remark"/>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="mini" type="primary" @click="firmwareRelease">确定</el-button>
            <el-button size="mini" @click="closeFirmwareReleasePage">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import {firmwareRelease} from "@/api/esaio/firmwareInfoApi";
import message from "@/utils/message";
import global from "@/components/Global.vue";
import cmdLoading from "@/utils/cmdLoading";

export default {
    name: "firmware-info-release",
    data() {
        return {

            dialogVisible: false,
            productModelName: '--',
            version: '--',
            releaseInfoDto: {

                firmwareInfoId: null,
                type: '',
                remark: ''
            },
            rules: {
                remark: [
                    {required: true, message: '发布原因不能为空', trigger: 'change' },
                    {max: 200, message: '发布原因长度不能超过200个字符', trigger: 'change'}
                ]
            },
            releaseLoading: null
        }
    },
    methods: {

        initPage(firmwareInfoId, productModelName, type, version) {

            this.dialogVisible = true;
            this.productModelName = productModelName;
            this.releaseInfoDto.type = type;
            this.version = version;
            this.releaseInfoDto.firmwareInfoId = firmwareInfoId;
        },
        firmwareRelease() {

            this.$refs['releaseFirmwareInfoForm'].validate((valid) => {

                if (valid) {

                    this.releaseLoading = cmdLoading.loading("固件发布中，请等待...", 30)
                    firmwareRelease(this.releaseInfoDto).then((res) => {

                        this.releaseLoading.close();
                        if (res.code === global.response_status_success_obj) {

                            message.success("发布成功");
                            this.dialogVisible = false;
                            this.$emit('queryFirmwareInfoList');
                        } else {

                            message.error(res.msg);
                        }
                    }).catch(err=> {

                        this.releaseLoading.close();
                        message.error('请求失败,请稍后再试');
                        console.error(err);
                    });
                }
            });
        },
        closeFirmwareReleasePage() {

            this.dialogVisible = false;
            this.$refs.releaseFirmwareInfoForm.resetFields();
        }
    }
}
</script>

<style scoped>

</style>