<template>
    <el-dialog title="固件发布记录" :visible.sync="dialogVisible" top="0vh" width="1000px"
               @close='dialogVisible = false' :close-on-click-modal="false" :destroy-on-close=true>
        <el-form :inline="true" :model="searchDto" class="demo-form-inline" size="mini">
            <el-form-item label="产品型号">
                <el-select v-model="searchDto.productModelId" placeholder="请选择" clearable @change="productModelChange">
                    <el-option v-for="item in productModelOptions" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>
            </el-form-item>
            <el-form-item label="固件类型">
                <el-select v-model="searchDto.type" placeholder="请选择" clearable>
                    <el-option v-for="item in firmwareTypeOption" :label="item" :value="item"/>
                </el-select>
            </el-form-item>
            <el-form-item label="固件版本">
                <el-input v-model="searchDto.version" placeholder="固件版本" clearable/>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="queryFirmwareReleaseLogList">查询</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="tableData" border size="small" stripe style="width: 100%" height="442"
            :header-cell-style="{background: '#eef1f6', color: '#606266'}" v-loading="listLoading">
            <el-table-column prop="productModelName" label="产品型号" width="140px"/>
            <el-table-column prop="firmwareType" label="固件类型" width="100px"/>
            <el-table-column prop="version" label="固件版本" width="120px"/>
            <el-table-column prop="releaseReason" label="发布说明"/>
            <el-table-column prop="releaseUser" label="发布人" width="120px"/>
            <el-table-column prop="releaseTime" align="center" label="发布时间" width="150px"/>
        </el-table>
        <div class="pagination">
            <el-pagination background layout="total, sizes, prev, pager, next, jumper"
               :total="tableTotal"
               :current-page.sync="searchDto.pageNum"
               :page-sizes="[10, 50, 100]"
               :page-size="searchDto.pageSize"
               @size-change="handleSizeChangeEvent"
               @current-change="handleCurrentChangeEvent"/>
        </div>
    </el-dialog>
</template>

<script>
import {productModeList, queryFirmwareTypeSelect, queryFirmwareReleaseLogList} from "@/api/esaio/firmwareInfoApi";
import global from "@/components/Global.vue";
import message from "@/utils/message";
export default {
    name: "firmware-release-log",
    data() {
        return {
            dialogVisible: false,
            // 产品型号列表
            productModelOptions: [],
            // 所有产品型号code对应的固件类型
            productModelFirmwareType: {},
            // 可选的固件类型列表，随产品型号改变而改变
            firmwareTypeOption: {},
            listLoading: false,
            searchDto: {
                pageNum: 1,
                pageSize: 10,
                productModelId: "",
                type: "",
                version: ""
            },
            tableData: [],
            tableTotal: 0,
        };
    },
    methods: {

        initPage() {

            this.dialogVisible = true;
            this.productModeList();
            this.queryFirmwareTypeSelect();
            this.queryFirmwareReleaseLogList();
        },
        productModeList() {

            productModeList().then((res) => {

                if (res.code === global.response_status_success_obj) {

                    this.productModelOptions = res.data;
                } else {

                    message.error(res.msg);
                }
            }).catch(err=> {

                message.error('请求失败,请稍后再试');
                console.error(err);
            });
        },
        queryFirmwareTypeSelect() {

            queryFirmwareTypeSelect().then((res) => {

                if (res.code === global.response_status_success_obj) {

                    this.productModelFirmwareType = res.data;
                } else {

                    message.error(res.msg);
                }
            }).catch(err=> {

                message.error('请求失败,请稍后再试');
                console.error(err);
            });
        },
        handleSizeChangeEvent(val) {

            this.searchDto.pageSize = val;
            this.queryFirmwareReleaseLogList();
        },
        handleCurrentChangeEvent(val) {

            this.searchDto.pageNum = val;
            this.queryFirmwareReleaseLogList();
        },
        // 查询页面数据
        queryFirmwareReleaseLogList() {

            this.listLoading = true;
            queryFirmwareReleaseLogList(this.searchDto).then((res) => {

                this.listLoading = false;
                if (res.code === global.response_status_success_obj) {

                    this.tableTotal = res.data.total;
                    this.tableData = res.data.result;
                } else {

                    message.error(res.msg);
                }
            }).catch(err=> {

                this.listLoading = false;
                message.error('请求失败,请稍后再试');
                console.error(err);
            });
        },
        productModelChange(e) {

            let productModelCode = e ? this.productModelOptions.find(obj => obj.id === e).code : '';
            this.searchDto.type = '';
            this.firmwareTypeOption = this.productModelFirmwareType[productModelCode];
        },
    }
}
</script>
<style scoped lang="scss">
.pagination {

    margin-top: 15px;
    text-align: right;
}
</style>
