1<template>
    <div class="panel-style">
        <el-form :inline="true" :model="searchDto" class="demo-form-inline" size="mini">
            <el-form-item label="产品型号">
                <el-select v-model="searchDto.productModelId" placeholder="请选择" clearable @change="productModelChange">
                    <el-option v-for="item in productModelOptions" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>
            </el-form-item>
            <el-form-item label="固件类型">
                <el-select v-model="searchDto.type" placeholder="请选择" clearable>
                    <el-option v-for="item in firmwareTypeOption" :label="item" :value="item"/>
                </el-select>
            </el-form-item>
            <el-form-item label="固件版本">
                <el-input v-model="searchDto.version" placeholder="固件版本" clearable></el-input>
            </el-form-item>
            <el-form-item label="是否发布">
                <el-select v-model="searchDto.isRelease" placeholder="请选择" clearable>
                    <el-option label="未发布" :value=false />
                    <el-option label="已发布" :value=true />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="queryFirmwareInfoList">查询</el-button>
                <el-button type="warning" @click="openAddFirmwareInfoPage">上传</el-button>
                <el-button type="success" @click="openReleaseLogPage">发布记录</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="tableData" border size="small" stripe style="width: 100%" height="550"
            :header-cell-style="{background: '#eef1f6', color: '#606266'}" v-loading="listLoading">
            <el-table-column prop="productModelName" label="产品型号" width="140px"/>
            <el-table-column prop="type" label="固件类型" width="100px"/>
            <el-table-column prop="name" label="固件名称"/>
            <el-table-column prop="size" label="固件大小(KB)" width="120px"/>
            <el-table-column prop="version" label="固件版本" width="120px"/>
            <el-table-column prop="isRelease" label="是否发布" width="120px" align="center">
                <template v-slot="scope">
                    <span v-if="scope.row.isRelease" style="color: #67C23A;">已发布</span>
                    <span v-else>未发布</span>
                </template>
            </el-table-column>
            <el-table-column prop="createBy" label="更新人" width="120px"/>
            <el-table-column prop="createTime" label="更新时间" align="center" width="150px"/>
            <el-table-column fixed="right" label="操作" align="center" width="90px">
                <template v-slot="scope">
                    <div class="option-btn-div">
                        <el-tooltip effect="dark" content="发布" placement="top">
                            <i v-if="scope.row.isRelease" class="el-icon-s-promotion option-btn-disable"/>
                            <i v-else class="el-icon-s-promotion option-btn-normal"
                               @click="openReleasePage(scope.row)"/>
                        </el-tooltip>
                        <el-tooltip effect="dark" content="删除" placement="top">
                            <i class="el-icon-delete option-btn-normal" style="color: #F56C6C" @click="deleteFirmwareInfo(scope.row)"/>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination">
            <el-pagination background layout="total, sizes, prev, pager, next, jumper"
               :total="tableTotal"
               :current-page.sync="searchDto.pageNum"
               :page-sizes="[10, 50, 100]"
               :page-size="searchDto.pageSize"
               @size-change="handleSizeChangeEvent"
               @current-change="handleCurrentChangeEvent"/>
        </div>
        <firmware-info-add ref="addFirmwareInfoPage" @queryFirmwareInfoList="queryFirmwareInfoList"/>
        <firmware-info-release ref="releasePage" @queryFirmwareInfoList="queryFirmwareInfoList"/>
        <firmware-release-log ref="releaseLogPage"/>
    </div>
</template>

<script>
import {queryFirmwareInfoList, removeFirmwareInfo, productModeList, queryFirmwareTypeSelect, checkFirmwareInfoIsItOccupied} from "@/api/esaio/firmwareInfoApi";
import global from "@/components/Global.vue";
import message from "@/utils/message";
import FirmwareInfoAdd from "@/views/esaio/firmware/firmware-info-add.vue";
import FirmwareInfoRelease from "@/views/esaio/firmware/firmware-info-release.vue";
import FirmwareReleaseLog from "@/views/esaio/firmware/firmware-release-log.vue";
export default {
    name: "firmware-info-list",
    components: {FirmwareReleaseLog, FirmwareInfoRelease, FirmwareInfoAdd},
    provide(){
        return{
            refreshTable: this.initPage
        }
    },
    data() {
        return {

            // 产品型号列表
            productModelOptions: [],
            // 所有产品型号code对应的固件类型
            productModelFirmwareType: {},
            // 可选的固件类型列表，随产品型号改变而改变
            firmwareTypeOption: {},
            listLoading: false,
            searchDto: {
                pageNum: 1,
                pageSize: 10,
                productModelId: "",
                type: "",
                version: "",
                isRelease: '',
            },
            tableData: [],
            tableTotal: 0
        };
    },
    methods: {

        initPage() {

            this.productModeList();
            this.queryFirmwareTypeSelect();
            this.queryFirmwareInfoList();
        },
        productModeList() {

            productModeList().then((res) => {

                if (res.code === global.response_status_success_obj) {

                    this.productModelOptions = res.data;
                } else {

                    message.error(res.msg);
                }
            }).catch(err=> {

                message.error('请求失败,请稍后再试');
                console.error(err);
            });
        },
        queryFirmwareTypeSelect() {

            queryFirmwareTypeSelect().then((res) => {

                if (res.code === global.response_status_success_obj) {

                    this.productModelFirmwareType = res.data;
                } else {

                    message.error(res.msg);
                }
            }).catch(err=> {

                message.error('请求失败,请稍后再试');
                console.error(err);
            });
        },
        handleSizeChangeEvent(val) {

            this.searchDto.pageSize = val;
            this.queryFirmwareInfoList();
        },
        handleCurrentChangeEvent(val) {

            this.searchDto.pageNum = val;
            this.queryFirmwareInfoList();
        },
        queryFirmwareInfoList() {

            this.listLoading = true;
            queryFirmwareInfoList(this.searchDto).then((res) => {

                this.listLoading = false;
                if (res.code === global.response_status_success_obj) {

                    this.tableTotal = res.data.total;
                    this.tableData = res.data.result;
                } else {

                    message.error(res.msg);
                }
            }).catch(err=> {

                this.listLoading = false;
                message.error('请求失败,请稍后再试');
                console.error(err);
            });
        },
        productModelChange(e) {

            let productModelCode = e ? this.productModelOptions.find(obj => obj.id === e).code : '';
            this.searchDto.type = '';
            this.firmwareTypeOption = this.productModelFirmwareType[productModelCode];
        },
        openAddFirmwareInfoPage() {

            this.$nextTick(() => {

                this.$refs.addFirmwareInfoPage.initPage();
            });
        },
        openReleasePage(firmwareInfo) {

            let params = {

                productModelId: firmwareInfo.productModelId,
                type: firmwareInfo.type
            }
            this.checkFirmwareInfoIsItOccupied('release', params, ()=>{

                this.$nextTick(() => {

                    this.$refs.releasePage.initPage(firmwareInfo.id, firmwareInfo.productModelName, firmwareInfo.type, firmwareInfo.version);
                });
            });
        },
        openReleaseLogPage() {

            this.$nextTick(() => {

                this.$refs.releaseLogPage.initPage();
            });
        },
        deleteFirmwareInfo(firmwareInfo) {

            message.confirm("确认要删除吗？", false, "warning", ()=>{

                this.listLoading = true;
                let params = {

                    id: firmwareInfo.id
                }
                this.checkFirmwareInfoIsItOccupied('delete', params, ()=>{

                    this.listLoading = true;
                    removeFirmwareInfo(params).then((res) => {

                        this.listLoading = false;
                        if (res.code === global.response_status_success_obj) {

                            this.queryFirmwareInfoList();
                        } else {

                            message.error(res.msg);
                        }
                    }).catch(err=> {

                        message.error('请求失败,请稍后再试');
                        console.error(err);
                        this.listLoading = false;
                    });
                });
            })
        },
        checkFirmwareInfoIsItOccupied(optionFlag, params, callback) {

            checkFirmwareInfoIsItOccupied(params).then((res) => {

                this.listLoading = false;
                if (res.code === global.response_status_success_obj) {

                    let data = res.data;
                    let deviceInfoList = data.deviceInfoList;
                    if (deviceInfoList.length > 0) {

                        let tipMsg = `名称：${data.name}，类型：${data.type}，版本：${data.version}`;
                        this.htmlCode = `
                            <div>已发布的固件【${tipMsg}】正被设备使用中，请在所有设备完成升级后再尝试删除该固件...</div></br>
                            <div class="table-container">
                                <table border='1' style='width: 100%; border-collapse: collapse; text-align: center;'>
                                    <thead>
                                        <tr>
                                            <th>站点名称</th>
                                            <th>产品型号</th>
                                            <th>设备编号</th>
                                            <th>当前进度</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        ${deviceInfoList.map(deviceInfo => `
                                            <tr>
                                                <td>${deviceInfo.stationName}</td>
                                                <td>${deviceInfo.productModelName}</td>
                                                <td>${deviceInfo.serialNumber}</td>
                                                <td>${deviceInfo.curProgress}%</td>
                                            </tr>
                                        `).join('')}
                                    </tbody>
                                </table>
                            </div>`;
                        this.$nextTick(() => {

                            this.$alert(this.htmlCode, optionFlag === 'release' ? '发布固件失败' : '删除固件失败', { dangerouslyUseHTMLString: true, type: 'warning', customClass: 'custom-alert-width' });
                        });
                    } else {

                        callback();
                    }
                } else {

                    message.error(res.msg);
                }
            }).catch(err=> {

                message.error('请求失败,请稍后再试');
                console.error(err);
                this.listLoading = false;
            });
        },
    },
    mounted() {

        this.initPage();
    }
}
</script>
<style>
.custom-alert-width {

    width: 550px !important;
}
.table-container {

    max-height: 200px;
    scrollbar-width: none;
    overflow-y: auto;
}
.table-container table {

    width: 100%;
    border-collapse: collapse;
}
.table-container thead th {

    position: sticky;
    top: 0;
    background-color: #f1f1f1;
    border-bottom: 2px solid black;
    z-index: 2;
}
.table-container tbody {

    max-height: 100px;
}
.table-container tbody td, .table-container thead th {

    border: 1px solid black;
    padding: 2px;
    text-align: center;
}
.table-container::-webkit-scrollbar {

    display: none;
}
</style>
<style scoped lang="scss">
.pagination {

    margin-top: 15px;
    text-align: right;
}
.option-btn-div {

    display: flex;
    justify-content: space-around
}
.option-btn-normal {

    font-size: 18px;
    color: #409EFF;
    cursor: pointer;
}
.option-btn-disable {

    font-size: 18px;
    color: #909399;
}
</style>
