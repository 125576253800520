import axios from 'axios';
import global from '../../components/Global';

let base = global.esaioServerBase;

export const productModeList = () => { return axios.post(base + '/esaioFirmwareInfo/productModeList')};

export const queryFirmwareTypeSelect = () => { return axios.post(base + '/esaioFirmwareInfo/queryFirmwareTypeSelect')};

export const queryFirmwareReleaseLogList = params => { return axios.post(base + '/esaioFirmwareInfo/queryFirmwareReleaseLogList', params)};

export const queryFirmwareInfoList = params => { return axios.post(base + '/esaioFirmwareInfo/queryFirmwareInfoList', params)};

export const checkFirmwareInfoVersionIsExist = params => { return axios.post(base + '/esaioFirmwareInfo/checkFirmwareInfoVersionIsExist', params)};

export const removeFirmwareInfo = params => { return axios.post(base + '/esaioFirmwareInfo/removeFirmwareInfo', params)};

export const firmwareRelease = params => { return axios.post(base + '/esaioFirmwareInfo/firmwareRelease', params)};

// 创建用户信息
export const saveFirmwareInfo = params => {

    let formData = new FormData();
    if (params.fileList && params.fileList.length > 0) {

        formData.append('file', params.fileList[0].raw);
    }
    formData.append("productModelId", params.dto.productModelId);
    formData.append("type", params.dto.type);
    formData.append("version", params.dto.version);
    return axios({

        url: global.esaioServerBase + '/esaioFirmwareInfo/saveFirmwareInfo',
        method: 'post',
        data: formData
    });
};

export const checkFirmwareInfoIsItOccupied = params => { return axios.post(base + '/esaioFirmwareInfo/checkFirmwareInfoIsItOccupied', params)};
