<template>
    <el-dialog id="addFirmwareInfoDialog" title="上传固件" :visible.sync="dialogVisible" top="0vh" center width="450px"
               :close-on-press-escape="false" @close="closeFirmwareInfoAddPage" :close-on-click-modal="false" :destroy-on-close=true>
        <el-form ref="addFirmwareInfoForm" :model="addDto" :rules="rules" label-width="100px" size="mini">
            <el-form-item label="产品型号" prop="productModelId">
                <el-select v-model="addDto.productModelId" @change="productModelChange" placeholder="请选择" clearable style="width: 250px">
                    <el-option v-for="item in productModelOptions" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>
            </el-form-item>
            <el-form-item label="固件类型" prop="type">
                <el-select v-model="addDto.type" placeholder="请选择" clearable style="width: 250px">
                    <el-option v-for="item in firmwareTypeOption" :label="item" :value="item"/>
                </el-select>
            </el-form-item>
            <el-form-item label="固件版本" prop="version">
                <el-input v-model="addDto.version" placeholder="请填写固件版本" style="width: 250px"></el-input>
            </el-form-item>
            <el-form-item label="固件文件" prop="file">
                <el-upload action="" :multiple="false" :auto-upload="false" :limit="1"  :file-list="fileList"
                    :on-remove="handleFileRemoveEvent" :on-change="fileOnChange" :on-exceed="handleImageExceedEvent">
                    <el-button size="mini" type="danger">上传</el-button>
                    <div slot="tip" class="el-upload__tip">只能上传bin文件,且不超过1MB</div>
                </el-upload>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="mini" type="primary" @click="addFirmwareInfoEvent">确定</el-button>
            <el-button size="mini" @click="closeFirmwareInfoAddPage">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import message from "@/utils/message";
import {productModeList, queryFirmwareTypeSelect, checkFirmwareInfoVersionIsExist, saveFirmwareInfo} from "@/api/esaio/firmwareInfoApi";
import global from "@/components/Global.vue";
import cmdLoading from "@/utils/cmdLoading";

export default {
    name: "firmware-info-add",
    data() {

        // 自定义校验规则：检查文件是否为空
        let checkFile = (rule, value, callback) => {

            if (this.fileList.length === 0) {

                callback(new Error('请选择一个固件bin文件'));
            } else {

                callback();
            }
        };
        return {

            dialogVisible: false,
            // 产品型号列表
            productModelOptions: [],
            // 所有产品型号code对应的固件类型
            productModelFirmwareType: {},
            // 可选的固件类型列表，随产品型号改变而改变
            firmwareTypeOption: {},
            productModelCode: '',
            addDto:{

                productModelId: null,
                type: "",
                version: ''
            },
            fileList:[],
            rules: {
                productModelId: [
                    {required: true, message: '产品型号不能为空', trigger: 'change'}
                ],
                type: [
                    {required: true, message: '固件类型不能为空', trigger: 'change'}
                ],
                version: [
                    {required: true, message: '固件版本不能为空', trigger: 'blur'}
                ],
                file:[
                    {validator: checkFile, trigger: 'change'}
                ]
            },
            addLoading: null
        };
    },
    methods: {

        initPage() {

            this.dialogVisible = true;
            this.productModeList();
            this.queryFirmwareTypeSelect();
        },
        productModeList() {

            productModeList().then((res) => {

                if (res.code === global.response_status_success_obj) {

                    this.productModelOptions = res.data;
                } else {

                    message.error(res.msg);
                }
            }).catch(err=> {

                message.error('请求失败,请稍后再试');
                console.error(err);
            });
        },
        queryFirmwareTypeSelect() {

            queryFirmwareTypeSelect().then((res) => {

                if (res.code === global.response_status_success_obj) {

                    this.productModelFirmwareType = res.data;
                } else {

                    message.error(res.msg);
                }
            }).catch(err=> {

                message.error('请求失败,请稍后再试');
                console.error(err);
            });
        },
        productModelChange(e) {

            this.productModelCode = e ? this.productModelOptions.find(obj => obj.id === e).code : '';
            this.addDto.type = '';
            this.firmwareTypeOption = this.productModelFirmwareType[this.productModelCode];
        },
        fileOnChange(file, fileList) {

            let validFileEndBinList = fileList.filter(file => this.validFileTypeIsBin(file));
            if (validFileEndBinList.length < fileList.length) {

                this.fileList = [];
                message.error('只能上传以 .bin 结尾的文件');
                return;
            }
            if (file.size > 1024 * 1024) {

                this.fileList = [];
                message.error("固件上传文件大小不能超过1MB！");
                return;
            }
            // AioMini类型设备文件名不能超过20个字符，因为下发指令的时候需要用到这个名字，限定不能超过20个字节
            if (this.productModelCode === 'AioMini') {

                let validFile20LengthList = fileList.filter(file => this.validAioMiniFileName(file));
                if (validFile20LengthList.length < fileList.length) {

                    this.fileList = [];
                    message.error('文件名称不能超过20个字符，包括.bin');
                } else {

                    this.fileList = fileList;
                }
            } else {

                this.fileList = fileList;
            }
        },
        validFileTypeIsBin(file) {

            return file.name.endsWith('.bin');
        },
        validAioMiniFileName(file) {

            return file.name.length <= 20;
        },
        addFirmwareInfoEvent() {

            this.$refs['addFirmwareInfoForm'].validate((valid) => {

                if (valid) {

                    checkFirmwareInfoVersionIsExist(this.addDto).then((res) => {

                        if (res.code === global.response_status_success_obj) {

                            if (res.data) {

                                this.addLoading = cmdLoading.loading("固件上传中，请等待...", 30)
                                let params = {

                                    dto: this.addDto,
                                    fileList: this.fileList
                                }
                                saveFirmwareInfo(params).then(res => {

                                    this.addLoading.close();
                                    if (res.code === global.response_status_success_obj) {

                                        message.success('固件上传成功');
                                        this.dialogVisible = false;
                                        this.$emit('queryFirmwareInfoList');
                                    } else {

                                        message.error(res.msg);
                                    }
                                }).catch(err => {

                                    this.addLoading.close();
                                    message.error('请求失败,请稍后再试');
                                    console.error(err);
                                });
                            } else {

                                message.error("当前固件版本号已存在，请检查！")
                            }
                        } else {

                            message.error(res.msg);
                        }
                    }).catch(err=> console.log(err));
                }
            });
        },
        handleFileRemoveEvent(file, fileList) {

            if (file.status === 'success') {

                this.fileList = [];
            }
        },
        handleImageExceedEvent() {

            message.error("只能上传一个固件，请先删除原有固件");
        },
        closeFirmwareInfoAddPage() {

            this.dialogVisible = false;
            this.$refs.addFirmwareInfoForm.resetFields();
            this.fileList = [];
        }
    }
}
</script>

<style scoped lang="scss">

</style>
